import React from "react";
import ImageBorder from "../components/ImageBorder";
import TextContainer from "../components/TextContainer";
import PabloPicture from "../img/pablo_jurado.jpg";

const AboutMePage = () => (
  <div className="about">
    <div className="img-layout">
      <ImageBorder>
        <img src={PabloPicture} alt="Pablo Jurado" width="100%" />
      </ImageBorder>
    </div>

    <div className="text-layout">
      <TextContainer>
        <h1>Hi there!</h1>
        <p>
          My name is Pablo Jurado, I am a Software Engineer based in Dallas
          Texas. I like to architect and build web application focusing on user
          experience and clean design. I have experience in Product Development,
          Software consulting, visual design, and teaching. In my free time, I
          like to cook and play music.
        </p>
        <a href="mailto:pjurado85@gmail.com">Get in touch</a>
      </TextContainer>
    </div>
  </div>
);

export default AboutMePage;
